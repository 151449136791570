<template>
  <div id="nav">
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<style>
*{
  font-family: 'Exo 2', sans-serif;
}
</style>
