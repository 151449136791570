import { createRouter, createWebHistory } from 'vue-router'


const routes = [
   {
    path: '/login',
    name: 'Login',
    component: () => import("../views/login.vue")
  },
  {
    path: '/changepassword',
    name: 'Changepassword',
    component: () => import("../views/changepassword.vue")
  },
  {
    path: '/register',
    name: 'Register',
    component:() => import("../views/register.vue")
  },
  {
    path: '/',
    name: 'Home',
    component:() => import("../views/Home.vue")
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import("../views/contact.vue")
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import("../views/faq.vue")
  },
  {
    path:'/publication/:id',
    name:'Publication',
    component: () => import("../views/publication.vue")
  },
  {
    path: '/istories',
    name: 'Istories',
    component: () => import("../views/istories.vue")
  },
  {
    path:'/istory/:id',
    name: 'Istory',
    component: () => import("../views/istory.vue")
  },
  {
    path:'/istoryv/:id',
    name: 'Istoryv',
    component: () => import("../views/istoryv.vue")
  },
  {
    path: '/country/:id',
    name: 'Country',
    component: () => import("../views/country.vue")
  },
 
  {
    path: '/elibrary',
    name: 'Elibrary',
    component: () => import("../views/elibrary.vue")
  },
  {
    path:'/mapping',
    name: 'Mapping',
    component: () => import("../views/mapping.vue")
  },
  {
    path:'/activitytrainings/:id',
    name: 'Event',
    component: () => import("../views/event.vue")
  },
  
  {
    path:'/training/:id',
    name: 'Events',
    component: () => import("../views/events.vue")
  },
  {
    path:'/componentOne/:id',
    name: 'ComponentOne',
    component: () => import("../views/componentOne.vue"),
    props:true
  },
  {
    // catch all 404 - define at the very end
    path: "/:pathMatch(.*)*",
    name: "404",
    component:() => import("../views/Home.vue")
    },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
